<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    title="Modalità di Pagamento"
    :route-father="routeFather"
  >
    <template v-slot:header-actions>
      <v-btn text link @click="goToNew">
        <v-icon class="mr-2">mdi-plus</v-icon> Nuovo
      </v-btn>
    </template>
    <template v-slot:content>
      <div class="d-flex justify-center align-center mb-2" style="width: 100%">
        <AdvancedFilter
          style="width:99%; height: 100%"
          v-model="filtersValue"
          :filters="filterTypes"
          :external-filter="externalFilter"
          @apply-filters="applyFilters"
        >
          <template v-slot:chip-custom-description="{filter}">
            Descrizione: {{ filter.value }}
          </template>

        </AdvancedFilter>
      </div>
      <div style="height: 100%; overflow-y: hidden" ref="dataTableWrapper">
        <TypeDataTable
          v-model="selected"
          loading-text="Caricamento modalità di pagamento ..."
          :headers="headers"
          :loading="loading"
          :items="filteredPaymentTerms"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
          :height="dataTableHeight"
          :show-select="false"
          single-select
          @edit="handleEdit"
          @delete="handleDelete"
        >
        </TypeDataTable>
      </div>
    </template>
  </FullScreenDialog >
</template>

<script>
import FullScreenDialog from '@/components/common/FullScreenDialog.vue'
import StandardMenu from '@/components/common/StandardMenu.vue'
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import paymentTermsService from '@/services/paymentTerms/paymentTerms.service.js'

export default {
  name: "PaymentTermsList",
  components: {
    FullScreenDialog,
    AdvancedFilter,
    TypeDataTable,
    StandardMenu
  },
  data: function() {
    let props = this.$router.resolve({name: 'Payments'});

    return {
      dialog: true,
      loadingItems: false,
      loadingHeaders: false,
      routeFather: props.href,
      selected: undefined,
      dataTableHeightValue: 400,
      paymentTerms: [],
      headers: [],
      page: 1,
      rowPerPage: 20,
      totalPages: 0,
      resizeListener: undefined,
      filterTypes: [
        { type: 'date', operator: 'between', field: 'createdAt', name: 'Data Creazione', label: 'Del giorno', color: "", value: [undefined, undefined] },
      ],
      externalFilter: { type: 'custom', operator: 'custom', field: 'description', name: 'Descrizione', label: 'Descrizione', color: "", value: undefined },
      filtersValue: [],
    }
  },
  props: {
    filters: {
      type: Array,
      default: () => []
    }
  },
  mounted: function() {
    this.fetchHeaders()
    this.filtersValue = this.filters
    this.fetchPaymentTerms()

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60
    this.resizeListener = function() {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60
    }
    this.resizeListener = this.resizeListener.bind(this)
    window.addEventListener('resize', this.resizeListener)
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    fetchPaymentTerms() {
      let filters = [...this.filtersValue]
      this.loadingItems = true
      paymentTermsService.list(this.page, this.rowPerPage, filters).then((results) => {
        if(this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage
        }

        if(this.page != results.page) {
          this.page = results.page
        }

        this.totalPages = results.totalPages
        if(this.totalPages < this.page && this.totalPages != 0) {
          this.page = this.totalPages
        }

        this.paymentTerms = results.rows
        this.loadingItems = false
      })
    },
    applyFilters() {
      this.fetchPaymentTerms();
    },
    fetchHeaders() {
      this.loadingHeaders = true
      paymentTermsService.fields().then((headers) => {
        this.headers = headers
        this.loadingHeaders = false
      })
    },
    handleEdit(paymentTerm) {
      this.$router.push({
        name: 'PaymentTermsEditForm',
        params: {
          id: paymentTerm.id,
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'PaymentTermsList'}).href,
          pathName: "PaymentTermsList"
        }
      })
    },
    handleDelete(paymentTerm) {
      let confirmed = confirm('Sei sicuro di voler eliminare la modalità di pagamneto?')

      if(confirmed) {
        this.loading
        paymentTermsService.delete(paymentTerm).then(() => {
          this.fetchPaymentTerms()
          this.loading
        })
      }
    },
    goToNew() {
      this.$router.push({
        name: 'PaymentTermsNewForm',
        query: {
          pathToGoBack: this.$router.resolve({name: 'PaymentTermsList'}).href
        }
      })
    }
  },
  computed: {
    filteredPaymentTerms() {
      return this.paymentTerms
    },
    loading() {
      return this.loadingItems || this.loadingHeaders
    },
    dataTableHeight() {
      return this.dataTableHeightValue + 'px'
    }
  },
  watch: {
    page() {
      this.fetchPaymentTerms()
    },
    rowPerPage() {
      this.fetchPaymentTerms()
    },
  }
}
</script>